import { render, staticRenderFns } from "./DMCA.vue?vue&type=template&id=5689208d&scoped=true&lang=pug&"
import script from "./DMCA.vue?vue&type=script&lang=ts&"
export * from "./DMCA.vue?vue&type=script&lang=ts&"
import style0 from "./DMCA.vue?vue&type=style&index=0&id=5689208d&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5689208d",
  null
  
)

export default component.exports